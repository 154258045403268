import Axios from "../axios";
import { outErr } from "./apiConfig";
const url = "/api/blog"

export default {
    get(id, out) {
        Axios.get(url + "/detail?blogId=" + id).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}