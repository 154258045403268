import Axios from "../axios";
import { toForm, outErr } from "./apiConfig";
const url = "/api/referral"

export default {
    detail(out) {
        Axios.get(url + "/detail").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    list(data, out) {
        Axios.get(url + "/list", data).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}